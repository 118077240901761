$( document ).ready( function() {
	$( '[data-fancybox]' ).fancybox();

	$( document ).on( 'click', '.js-scroll-to', function( e ) {
		e.preventDefault();
		const link = $( this ).attr( 'href' );
		if ( link !== '' ) {
			$( 'html, body' ).animate({ 'scrollTop': $( link ).offset().top - 80 }, 600 );
		}
	});

	$( document ).on( 'click', '.js-menu-toggle', function () {
		if ( !$( this ).hasClass( 'active' ) ) {
			$( this ).addClass( 'active' );
			$( '.top-menu' ).addClass( 'show' );
		} else {
			$( this ).removeClass( 'active' );
			$( '.top-menu' ).removeClass( 'show' );
		}
	});

	$( document ).on( 'click', '.products-tabs li', function () {
		const $this = $( this );
		if ( !$this.hasClass( 'active' ) ) {
			$( '.products-tabs li' ).removeClass( 'active' );
			$this.addClass( 'active' );
			$( '.products-box' ).removeClass( 'show' );
			$( '.products-box[data-tab="' + $this.attr( 'data-tab' ) + '"]' ).addClass( 'show' );
		}
		if ( window.matchMedia( '(max-width: 767px)' ).matches ) {
			$( 'html, body' ).animate({ 'scrollTop': $( '.products-content' ).offset().top - 60 }, 600 );
		}
	});
});
